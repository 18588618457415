@import '../../../../../styles/variables';

.container {
  @include row;
  justify-content: space-between;
  padding: 30px;
  @media screen and (max-width: $sm-screen) {
    padding: 20px;
  }
  &.badges {
    justify-content: flex-start;
    .col-2 {
      width: auto;
      margin-right: 20px
    }
  }
  .block {
    @include block;
    margin-bottom: 30px;
    .badge {
      width: 230px;
    }
    .archived {
      label {
        font-family: "Montserrat-SemiBold", sans-serif;
        display: block;
        font-size: 14px;
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 5px;
      }
    }
    h2 {
      margin-bottom: 10px;
    }
    .row {
      @include row;
      justify-content: space-between;
      &.nested {
        padding-left: 20px;
        .col-2 {
          width: 48%;
        }
      }
      &.border {
        border-bottom: 1px solid rgba($dark, 0.1);
        margin-bottom: 20px;
      }
    }
  }
}