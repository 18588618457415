@import '../../../../styles/variables';

.editor {
  min-height: 148px;
  border-radius: 3px;
  background: #FBFBFB;
  border: 1px solid #DFDFDF;
  padding: 0px 10px;
  .public-DraftStyleDefault-block {
    margin: 0px;
  }
}

.copy {
  button {
    height: 30px;
    background: white;
    cursor: pointer;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    margin-top: 3px;
    margin-right: 3px;
    padding: 0px 10px;
    text-transform: capitalize;
    &:hover {
      box-shadow: 1px 1px 0px #BFBDBD;
      background-color: #FFFFFF;
    }
  }
}