@import '../../../../../styles/variables';

.container {
  .list {
    flex-direction: column;
    margin-top: 10px;
    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 15px 0px 15px 10px;
      border-radius: 6px;
      font-size: 14px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 13px -2px rgba(156, 167, 177, 0.3);
      .col {
        width: 40%;
        &:not(:first-child) {
          width: 30%;
        }
        display: flex;
        justify-content: flex-start;
      }
    }
  }  
}