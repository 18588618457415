@import '../../../../styles/variables';

$now: #46773E;
$coming: #E06308;
$finished: #ddb908;
$archived: #878787;
.container {
  @include page-container;
  .header {
    @include page-header;
  }
  .content {
    @include page-content;
    .row {
      @include row;
      .input {
        @include input;
      }
      .col {
        width: calc(50% /  3);
        &.name {
          width: 50%;
          padding: 0px 25px 0px 5px;
        }
      }
      &.actions {
        justify-content: space-between;
        .btn-edit {
          @include btn-edit;
          height: 36px;
          padding: 0px 15px;
          margin: 10px;
        }
      }
      &.sort {
        padding: 0px 5px;
        @media screen and (max-width: $md-screen) {
          display: none;
        }
        .col {
          &:first-child {
            padding-left: 0px;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0;
          }
        }
      }
      &:not(.label) {
        @include list-row;
        @media screen and (max-width: $md-screen) {
          position: relative;
          align-items: center;
          .updated-label {
            left: -4px;
            top: -4px;
            transform: none;
          }
        }
        .col {
          @media screen and (max-width: $md-screen) {
            width: 100%;
            margin: 5px;
          }
        }
        .name {
          @include bold;
        }
        .status {
          width: calc(100% - 20px);
          padding: 10px;
          text-align: center;
          border-radius: 3px;
          &.now {
            background-color: rgba($now, 0.2);
            color: $now;
            border: 1px solid $now;
          }
          &.archived {
            background-color: rgba($archived, 0.2);
            color: $archived;
            border: 1px solid $archived;
          }
          &.finished {
            background-color: rgba($finished, 0.2);
            color: $finished;
            border: 1px solid $finished;
          }
          &.coming {
            background-color: rgba($coming, 0.2);
            color: $coming;
            border: 1px solid $coming;
          } 
        }
      }
    }
  }
}