@import './variables.scss';
@import './tooltip.scss';

html, body {
  font-family: "Montserrat-Regular", sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  color: $dark;
}

* {
  margin:0;
  padding:0;
}

*:focus {
  outline: none;
}

body {
  height: 100%;
  background-color: $bg-grey;
}

iframe {
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 14px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.text-primary {
  color:$primary;
}

.text-orange {
  color:$orange;
}

.text-error {
  color:$error;
}

.text-success {
  color:$success;
}

.text-center {
  text-align: center;
}

.bold {
  font-family: "Montserrat-SemiBold", sans-serif;
}

.uppercase {
  text-transform: uppercase;
}

.regular {
  @include regular;
}


input, button {
  font-family: "Montserrat-Regular", sans-serif;
}

.ml-20 {
  margin-left: 20px;
}

.rotate180 {
  transform: rotate(180deg);
}

.tel {
  color: $primary;
  text-decoration: underline;
}

.hide-mobile {
  display: block;
  &.text {
    display: inline-block;
    @media screen and (max-width: $sm-screen) {
      display: none;
    }
  }
  @media screen and (max-width: $sm-screen) {
    display: none;
  }
}

.hide-tablet {
  @media screen and (max-width: $md-screen) {
    display: none;
  }
}

.hide-desktop {
  display: none;
  @media screen and (max-width: $sm-screen) {
    display: block;
  }
}

.app-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.link {
  cursor: pointer;
}

.slide-left {
  animation-name: slide-left;
  animation-duration: 0.5s;
}

@keyframes slide-left {
  from {
    transform: translate( 70px,0);
    opacity: 0;
  }
  to {
    transform: translate(0,0);
    opacity: 1;
  }
}

.slide-bottom {
  animation-name: slide-bottom;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

@keyframes slide-bottom {
  from {
    transform: translate( 0, 70px);
    opacity: 0;
  }
  to {
    transform: translate(0,0);
    opacity: 1;
  }
}

.page-container {
  @include page-container;
}

.page-header {
  @include page-header;
  .export {
    position: absolute;
    right: 30px;
    display: none;
    @media screen and (min-width: $sm-screen) {
      display: flex;
    }
  }
}

.page-content {
  @include page-content;
  .status {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      background-color: transparent;
      border: none;
      margin-left: 18px;
      display: flex;
      text-decoration: underline;
      cursor: pointer;
      color: #222859;
      img {
        margin-right: 6px;
      }
      &.delete {
        color: #E21414;
      }
    }
  }
}

.row {
  @include row;
}

.search-container {
  .select {
    margin: 5px 20px;
    @media screen and (max-width: $md-screen) {
      margin: 22px 0px;
    }
  }
}

.input {
  @include input;
}

textarea {
  border: 1px solid rgba($color: #0A1760, $alpha: 0.28);
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  padding: 0px 10px;
  max-width: 100%;
}

.icon {
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: $primary;
  &.transparent {
    background-color: transparent;
  }
  &.product {
    background-color: transparent;
    img {
      width: 25px;
      height: 25px;
    }
  }
  img {
    width: 20px;
    height: 20px;
  }
}

.title {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  h2 {
    border-bottom: 1px solid $primary;
  }
  .btn-edit {
    margin-left: 20px;
  }
}

.card {
  width: auto;
  margin: 10px 0px 20px 0px;
  padding: 20px 0px 20px 20px;;
  border-radius: 6px;
  font-size: 14px;
  background-color: $white;
  box-shadow: $shadow;
  h1 {
    margin-bottom: 20px;
  }
  .btn-container {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin:10px;
    }
    @media screen and (max-width: $sm-screen) {
      margin-right: 0px;
      flex-direction: column;
    }
  }
  &.notes {
    padding: 20px;
    min-height: 100px;
  }
  &.showroom-opt {
    padding: 20px;
    min-height: 250px;
  }
  &.info {
    min-height: 370px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .products {
    display: flex;
    margin-bottom: 10px;
    p {
      background-color: $primary;
    }
  }
  .product-img {
    max-width: calc(100% - 20px);
    margin-bottom: 20px;
    img {
      width: 100%;
      max-height: 400px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: $sm-screen) {
    padding: 20px;
  }
}

.search-error {
  margin-top: 20px;  
}


.badge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  h1 {
    text-transform: uppercase;
  }
  img {
    width: 150px;
  }
  .logo-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
    img {
      width: 50px;
      border-radius: 28px;
    }
  }
  @media screen and (max-width: $sm-screen) {
    min-height: 70vh;
  }
}

.btn-qr-container {
  transition: 0.8s all 0.2s ease-in-out;
  position: fixed;
  top: calc(100vh - 90px);
  left: 240px;
  width: calc(100vw - 240px);
  display: flex;
  justify-content: center;
  background: linear-gradient( transparent ,$white);
  transform: translateY(100px);
  &.isActive {
    transform: translateY(0px);
  }
  @media screen and (max-width: $sm-screen) {
    align-items: center;
    width: 100%;
    left: 0px;
  }
}

.stands-dashboard {
  display: flex;
  background-color: $primary;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  @media screen and (max-width: $sm-screen) {
    flex-direction: column;
  }
  .stands-products {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
    .count {
      width: calc((100% / 5) - 40px);
      padding: 20px;
      color: $white;
      @media screen and (max-width: $sm-screen) {
        width: calc(50% - 40px);
      }
      label {
        font-weight: bold;
        font-size: 14px;
      }
      h2 {
        font-size: 25px;
      }
    }
  }
  .stands-global {
    width: 30%;
    background-color: $primary-dark;
    border-radius: 0px 6px 6px 0px;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
      background-color: $primary-dark;
      border-radius: 0px 0px 6px 6px;
    }
    .count {
      text-align: right;
      padding: 20px;
      color: $white;
      @media screen and (max-width: $sm-screen) {
        text-align: left;
      }
      label {
        font-weight: bold;
        font-size: 14px;
      }
      h2 {
        font-size: 25px;
      }
    }
  }
}

.btn-scan {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: $orange;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px; 
  }
}

