@import './variables.scss';

.info {
  min-width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $sm-screen) {
    flex-wrap: wrap-reverse;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  &.order {
    min-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media screen and (max-width: $sm-screen) {
      flex-direction: column;
      align-items: flex-end;
    }
    p {
      width: calc(100% / 6 );
      text-align: center;
      @media screen and (max-width: $sm-screen) {
        width: auto;
      }
      &.products {
        font-size: 12px;
      }
      &.name {
        text-align: left;
      }
      &.total {
        text-align: center;
        @media screen and (max-width: $sm-screen) {
          text-align: right;
        }
      }
      &.statut {
        text-align: center;
        @media screen and (max-width: $sm-screen) {
          text-align: right;
        }
      }
    }
    .picto {
      display: flex;
      width: calc(100% / 6 );
      text-align: center;
      justify-content: flex-end;
    }
  }
  &.notifications {
    @media screen and (max-width: $sm-screen) {
      width: calc(100% - 40px);
      padding: 10px 20px;
      .updated-label {
        display: none;
      }
    }
    .title {
      width: 20%;
      padding-left: 10px;
      @media screen and (max-width: $sm-screen) {
        width: 100%;
      }
    }
    .text {
      width: 50%;
      padding: 10px;
      @media screen and (max-width: $sm-screen) {
        width: 100%;
        padding: 0px;
      }
    }
  }
  &.reception {
    min-width: 70%;
    @media screen and (max-width: $sm-screen) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      min-width: 0px;
    }
  .btn-is-here {
    cursor: pointer;
    padding: 10px 20px ;
    border-radius: 20px;
    &:hover {
      background-color: rgba(147, 157, 167, 0.2);
    }
    @media screen and (max-width: $sm-screen) {
      padding: 0px;
      border-radius: 20px;
      &:hover {
        background-color: rgba(147, 157, 167, 0.0);
      }
    }   
  }
  .company {
    min-width: 30%;
  }
  .badges-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
      margin-top: 20px;
    }
  }
  }
  &.product-order {
    min-width:  100%;
    p {
      max-width: 135px;
    }
    @media screen and (max-width: $md-screen) {
      margin-top: 10px;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    p {
      min-width: 138px;
      text-align: center;
      @media screen and (max-width: $md-screen) {
        min-width: 50%;
        margin: 5px 0px;
      }
    }
    .price {
      min-width: 85px;
      text-align: right;  
      @media screen and (max-width: $md-screen) {
        text-align: left; 
        min-width: 50%;
      } 
    }
  }
  &.offer {
    width:  85%;
    @media screen and (max-width: $md-screen) {
      margin-top: 10px;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    p {
      width: 20%;
      text-align: center;
      @media screen and (max-width: $md-screen) {
        width: 50%;
        margin: 5px 0px;
      }
    }
    .status {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      margin: 5px 0px;
      @media screen and (max-width: $md-screen) {
        min-width: 50%;
        margin: 5px 0px;
      }
      p {
        width: 100%;
        padding-right: 8px;
        text-align: right;
      }
      button {
        background-color: rgba($dark, 0.1);
        cursor: pointer;
        border: none;
        padding: 6px 10px;
        border-radius: 20px;
        color: rgba($dark, 0.5);
        &:hover {
          background-color: $success;
          color: $white;
        }
      }
    }
    .price {
      width: 20%;
      text-align: right;  
      @media screen and (max-width: $md-screen) {
        text-align: left; 
        min-width: 50%;
      } 
    }
  }
  &.assembly {
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .hours {
      min-width: 40px;
      text-align: center;
    }
    .volume {
      min-width: 75px;
      text-align: right;   
    }
    .btn-is-here {
      cursor: pointer;
      padding: 10px 20px ;
      border-radius: 20px;
      &:hover {
        background-color: rgba(147, 157, 167, 0.2);
      }
      @media screen and (max-width: $sm-screen) {
        padding: 0px;
        border-radius: 20px;
        &:hover {
          background-color: rgba(147, 157, 167, 0.0);
        }
      }   
    }
  }
  &.stand {
    min-width: 75%;
    @media screen and (max-width: $sm-screen) {
      margin-top: 20px;
      flex-direction: row;
    }
    .code-number {
      min-width: 60px;
      text-align: center;
      @media screen and (max-width: $sm-screen) {
        min-width: 0px;
        margin-right: 10px;
      }
    }
    .univers {
      width: 120px;
      text-align: center;
      font-size: 12px;
      @media screen and (max-width: $sm-screen) {
        display: none;
      }
    }
    .shape {
      min-width: 120px;
      text-align: center;
      @media screen and (max-width: $sm-screen) {
        min-width: 0px;
      }
    }
    .picto {
      display: flex;
      min-width: 13px;
      @media screen and (max-width: $sm-screen) {
        margin-top: 0px;
      }
    }
  }
  &.shop {
    min-width: 75%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $sm-screen) {
      margin-top: 20px;
    }
    .picto {
      display: flex;
      justify-content: center;
      @media screen and (max-width: $sm-screen) {
        margin: 0px;
      }
      .user-slash {
        width: 26px;
        transform: translateX(-20px)
      }
    }
    .centrale {
      text-align: center;
      text-transform: uppercase;
      width: 8%;
      @media screen and (max-width: $sm-screen) {
        width: 50%;
        text-align: left;
      }
    }
    .city {
      text-align: center;
      width: 20%;
      @media screen and (max-width: $sm-screen) {
        width: 50%;
        text-align: right;
      }
    }
    .btn-is-here {
      cursor: pointer;
      padding: 10px 20px ;
      border-radius: 20px;
      &:hover {
        background-color: rgba(147, 157, 167, 0.2);
      }
      @media screen and (max-width: $sm-screen) {
        padding: 0px;
        border-radius: 20px;
        &:hover {
          background-color: rgba(147, 157, 167, 0.0);
        }
      }   
    }
  }
  &.dashboard {
    min-width: 55%;
  }
  &.product {
    min-width: 83%;
    p {
      width: calc(83% / 6);
    }
    .counter {
      width: calc(85% / 4);
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        @include btn;
        margin: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          transform: scale(0.8);
          @include transition;
        }
        &:hover {
          img {
            transform: scale(0.9);
          }
        }
      }
      &.input {
        margin: 0px;
      }
      input {
        max-width: 30%;
        text-align: center;
      }
    }
  }
  .picto-stand-list {
    display: flex;
    @media screen and (max-width: $sm-screen) {
      margin-bottom: 10px;
    }
    .picto {
      display: flex;
      min-width: 30px;
    }
  }
  .contacts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.not-active {
      opacity: 0.2;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
    }
    p {
      min-width: 15px;
      text-align: center;
    }
    img {
      margin: 8px;
      @media screen and (max-width: $sm-screen) {
        margin: 0px 4px 0px 12px;
      }
    }
  }
  .stock {
    display: flex;
    min-width: 95px;
    justify-content: space-between;
    align-items: center;
    &.not-active {
      opacity: 0.2;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
      min-width: 0px;
      justify-content: flex-start;
    }
    img {
      margin-right: 15px;
      margin-left: 10px;
      @media screen and (max-width: $sm-screen) {
        margin-right: 0px;
      }
    }
  }
  .picto {
    display: flex;
    min-width: 55px;
    justify-content: space-between;
    align-items: center;
    .user {
      margin-left: 10px;
    }
    .check {
      margin-left: 10px;
      width: 20px;
    };
    &.anim-space {
      width: 33%;
      @media screen and (max-width: $sm-screen) {
        width: auto;
      }
      p {
        @media screen and (max-width: $sm-screen) {
          text-align: right;
        }
      }
    }
    &.not-active {
      opacity: 0.2;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
    }
    img {
      margin-right: 0px;
      margin-left:  0px;
      @media screen and (max-width: $sm-screen) {
        margin-right: 0px;
      }
    }
  }
  .statut {
    min-width: 74px;
    text-align: right;
    @media screen and (max-width: $sm-screen) {
      text-align: left;
    }
  }
  .company-status {
    .icon {
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 20px;
      margin-right: 0px;
      opacity: 0;
      &.valid {
        border: 1px solid #13B467;
        background-color: rgba(#13B467, 0.2);
        opacity: 1;
        img {
          transform: scale(0.7);
        }
      }
      &.confirmed {
        border: 1px solid #13B467;
        background-color: rgba(#13B467, 0.2);
        opacity: 1;
        img {
          transform: scale(0.7);
        }
      }
      &.pending {
        border: 1px solid #FF8900;
        background-color: rgba(#FF8900, 0.2);
        opacity: 1;
      }
      &.refused {
        border: 1px solid #E32A2A;
        background-color: rgba(#E32A2A, 0.2);
        opacity: 1;
        img {
          transform: scale(0.7);
        }
      }
    }
  }
  .stand {
    display: flex;
    min-width: 150px;
    justify-content: flex-end;
    align-items: center;
    &.shop {
      margin-right: 10px;
      @media screen and (max-width: $sm-screen) {
        margin-right: 0px;
      }
    }
    &.not-active {
      opacity: 0.2;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 10px;
      margin-bottom: 10px;
      min-width: 100%;
      justify-content: flex-end;
    }
    img {
      margin-right: 15px;
      margin-left: 10px;
      @media screen and (max-width: $sm-screen) {
        margin-right: 0px;
      }
    }
  }
  .text-center {
    min-width: 30px;
  }
}

.list-row {
  @include list-row;
}

.btn-export-container {
  width: 36%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: $md-screen) {
    width: 100%;
  }
  button {
    min-width: 100px;
    padding: 10px 20px;;
    height:36px;
    margin: 5px 0px 0px 20px;
    @media screen and (max-width: $md-screen) {
      margin: 10px 0px 0px 20px;
    }
  }
}

.not-active {
  opacity: 0.2;
}

.counters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $md-screen) {
    flex-direction: column;
  }
  .col {
    width: 45%;
    padding: 20px;
    color: $white;
    box-shadow: $shadow;
    border-radius: 6px;
    margin-bottom: 20px;
    @media screen and (max-width: $md-screen) {
      width: calc(100% - 40px);
    }
    span {
      font-size: 30px;
    }
    &.providers {
      background-color: $primary;
    }
    &.shops {
      background-color: $orange;
    }
  }
}